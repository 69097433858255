/* Navbar.css */

/* Base styles for the app bar */
.MuiAppBar-root {
    background-color: #fff; /* Ensure the background color is white */
    top: 0;
}
  
/* Styles for the title */
.title {
    text-decoration: none;
    color: black;
    font-weight: bold;
}
  
/* Styles for the button links */
.nav-button {
    background-color: inherit;
    color: black;
    position: relative;
    transition: color 0.3s ease;
}
  
/* Hover effect for the button links */
.nav-button:hover {
    color: #000000;
}
  
.nav-button::after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0%;
    content: '.';
    color: transparent;
    background: #aaa;
    height: 1px;
    transition: width 0.5s;
}
  
.nav-button:hover::after {
    width: 100%;
}
  
.nav-button-active {
    color: #555;
}
  
.nav-button-active::after {
    width: 100%;
    background: #aaa;
    font-weight: 500;
}
  
/* Custom hamburger icon */
.hamburger-icon {
    width: 30px;
    height: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
  
.hamburger-icon span {
    display: block;
    width: 100%;
    height: 4px;
    background-color: black;
}
  
.drawer-list-item {
    color: black;
    text-decoration: none;
}
  
/* Hover effect for drawer items */
.drawer-list-item:hover {
    background-color: #e0e0e0; /* Highlight color */
}
