.about-us-container {
  margin-top: 70px;
  overflow-x: hidden;
}

.about-us-image {
  width: 100%;
  height: 400px;
  object-fit: fill;
  margin-bottom: 70px;
}

.about-us-content {
  display: flex;
  flex-direction: row;
  margin: 10px;
}

.about-us-header {
  width: 430px;
  padding-top: 30px;
  margin: 15px;
  text-align: center;
}

.about-us-title {
  color: #BFBFBF;
  font-family: 'gilroyextrabold', sans-serif;
  font-size: 55px;
  font-weight: bold;
  letter-spacing: 0.03em;
}

.about-us-description {
  width: 1000px;
  padding: 10px 70px;
  display: flex;
  justify-content: space-between;
}

.about-us-paragraph {
  width: 400px;
  margin: 0 20px;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  color: #333;
  line-height: 1.6;
}

.our-strength-section {
  padding: 50px 0;
  background-color: #f4f4f4;
  text-align: center;
  border-radius: 10px;
  margin: 20px 0;
}

.section-title {
  color: #BFBFBF;
  font-family: 'gilroyextrabold', sans-serif;
  font-size: 55px;
  font-weight: bold;
  letter-spacing: 0.03em;
  padding: 10px;
}

.strength-content {
  display: flex;
  flex-direction: row;
  margin: 0 10px;
}

.strength-text {
  margin: 0 40px;
  width: 550px;
  height: 350px;
  padding: 5px 40px;
}

.strength-heading {
  color: #BFBFBF;
  font-family: 'gilroyextrabold', sans-serif;
  font-size: 55px;
  font-weight: bold;
  letter-spacing: 0.03em;
  padding: 10px;
}

.strength-paragraph {
  padding: 10px 35px;
  font-family: 'Gilroy-Regular', sans-serif;
  font-size: 16px;
  color: #555;
}

.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

.expertise-grid-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.expertise-grid-item:hover {
  transform: scale(1.05);
}

.expertise-card {
  padding: 1.5rem;
  text-align: center;
}

.expertise-title-img {
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  color: #222;
  margin-bottom: 1rem;
  position: relative;
}

.expertise-title-img::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 3px;
  width: 50px;
  background-color: #111;
}

.expertise-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 0;
}

@media (max-width: 768px) {
  .strength-text {
    padding: 10px;
  }

  .strength-heading {
    font-size: 45px;
  }

  .expertise-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
}

@media (max-width: 480px) {
  .strength-heading {
    font-size: 35px;
  }

  .expertise-title-img {
    font-size: 1.1rem;
  }

  .expertise-description {
    font-size: 0.875rem;
  }
}
