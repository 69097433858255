/* Add these styles to your CSS file */
.machinary-icon-container {
    text-align: center;
    padding: 20px;
  }
  
  .machinary-title h3 {
    color: #BFBFBF;
  font-family: 'gilroyextrabold', sans-serif;
  font-size: 55px;
  font-weight: bold;
  letter-spacing: 0.03em;
  padding: 10px;
  }
  
  .machinary-icons-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 10px;
  }
  
  .machinary-icon-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    overflow: hidden;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .machinary-icon-card:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .machinary-icon-image {
    width: 100%;
    height: auto;
    border-bottom: 2px solid #ddd;
  }
  
  .machinary-icon-details {
    padding: 15px;
  }
  
  .machinary-icon-item {
    font-size: 20px;
    font-weight: bold;
    color: #555;
  }
  
  .machinary-icon-title {
    font-size: 16px;
    color: #777;
    margin-top: 5px;
  }
  