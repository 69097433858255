.gallery-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
}

.images-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
}

.image-item {
  flex: 1 1 calc(25% - 20px);
  max-width: calc(25% - 20px);
  height: auto;
  position: relative;
}

.gallery-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-item img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-item img:hover,
.gallery-video:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1200px) {
  .image-item {
    flex: 1 1 calc(33.33% - 20px);
    max-width: calc(33.33% - 20px);
  }
}

@media screen and (max-width: 900px) {
  .image-item {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media screen and (max-width: 600px) {
  .image-item {
    flex: 1 1 calc(100% - 20px);
    max-width: calc(100% - 20px);
  }
}

@media screen and (max-width: 400px) {
  .gallery-container {
    margin-top: 50px;
  }
}
