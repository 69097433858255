.main {
  height: 36vmax;
  width: 100%;
  margin-top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f5f7fa, #8d96a3);
  padding: 20px;
  transition: background 0.5s ease-in-out, transform 0.5s ease-in-out;
  border-radius: 12px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Optional: Uncomment to enable hover background change */
/* .main:hover {
  background: linear-gradient(135deg, #c3cfe2, #f5f7fa);
  transform: translateY(-5px);
} */

.rsis-container {
  width: 300px;
  height: 200px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border-radius: 10px;
  overflow: hidden;
}

.rsis-container:hover {
  transform: scale(1.08);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.25);
}

.rsis-image {
  background-size: cover; /* Better for full coverage */
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  transition: opacity 0.3s ease-in-out;
}

.rsis-container:hover .rsis-image {
  opacity: 0.9; /* Slight dimming on hover for effect */
}

.sliderImage {
  height: 40px;
  width: 90%;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.sliderImage:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 780px) {
  .main {
    height: 45vmax;
    border: 1px solid black;
    background: linear-gradient(135deg, #e2eafc, #f5f7fa);
    border-radius: 8px;
  }

  .sliderImage {
    height: 40%;
    width: 90%;
    border-radius: 6px;
  }
}
