.footer-container {
  background-color: #333;
  color: #fff;
  padding: 20px 0;
}

.footer-content-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
}

.footer-column {
  flex: 1;
  min-width: 200px;
  margin-bottom: 12px;
}

.footer-title {
  font-size: 16px;
  margin-bottom: 12px;
}

.footer-text {
  font-size: 13px;
  color: #ccc;
}

.footer-links {
  display: flex;
  flex-direction: column;
}

.footer-link {
  margin-bottom: 6px;
  color: inherit;
  text-decoration: none;
}

.footer-link:hover {
  text-decoration: underline;
}

.social-icons {
  display: flex;
  justify-content: start;
}

.social-icon {
  color: inherit;
  margin-right: 12px;
  font-size: 18px;
}

.footer-bottom {
  padding: 12px 0;
  text-align: center;
}

.footer-content {
  margin-bottom: 6px;
  font-size: 13px;
}

.social-icons {
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.social-icon {
  display: inline-flex;
  color: inherit;
  margin-right: 12px;
  font-size: 18px;
  text-align: center;
  vertical-align: middle;
}

.social-icon i {
  display: inline-block;
  vertical-align: middle;
  margin: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .footer-content-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    min-width: 100%;
    text-align: center;
  }

  .footer-title {
    font-size: 14px;
  }

  .footer-text {
    font-size: 12px;
  }

  .social-icon {
    font-size: 16px;
    margin-right: 8px;
  }

  .footer-bottom {
    padding: 8px 0;
  }

  .footer-content {
    font-size: 12px;
  }
}

@media (max-width: 480px) {
  .footer-content-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-column {
    min-width: 100%;
    text-align: center;
  }

  .footer-title {
    font-size: 12px;
  }

  .footer-text {
    font-size: 11px;
  }

  .social-icon {
    font-size: 14px;
    margin-right: 6px;
  }

  .footer-bottom {
    padding: 6px 0;
  }

  .footer-content {
    font-size: 11px;
  }
}
