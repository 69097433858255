/* ContactUs.css */

.container {
  margin-top: 70px;
}

.banner-image {
  width: 100%;
  height: 300px;
}

.form-container {
  display: flex;
  justify-content: center;
  padding: 50px;
  gap: 50px;
  flex-wrap: wrap; /* Allow flex items to wrap for smaller screens */
}

.form-box, .info-box {
  flex: 1;
  max-width: 400px;
  padding: 40px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.info-box {
  max-width: 600px;
}

.form-title {
  text-align: center;
  margin-bottom: 20px;
  color: #5b5b5b;
  font-size: 30px;
}

.input-group {
  margin-bottom: 15px;
}

.input-label {
  display: block;
  margin-bottom: 5px;
  color: #f90;
}

.input-field {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #f90;
}

.submit-btn {
  width: 100%;
  background-color: #f90;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
}

.info-title {
  margin-bottom: 10px;
  font-size: 30px;
  color: #5b5b5b;
}

.info-text {
  font-size: 16px;
  margin-bottom: 10px;
}

.responsive-map {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

.responsive-map iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: absolute;
}

/* Responsive styles */
@media (max-width: 768px) {
  .form-container {
      padding: 30px;
      gap: 30px;
  }

  .form-box, .info-box {
      max-width: 100%;
  }

  .banner-image {
      height: 200px;
  }
}

@media (max-width: 480px) {
  .form-container {
      padding: 20px;
      gap: 20px;
  }

  .banner-image {
      height: 150px;
  }

  .input-field {
      padding: 8px;
  }

  .submit-btn {
      padding: 8px 16px;
      font-size: 14px;
  }
}
