/* Container for the expertise section */
.expertise-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  padding: 2rem;
  max-width: 1200px;
  background-color: #f4f4f4; /* Light grey background for the section */
  border-radius: 10px; /* Rounded corners for a softer look */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Row for the image and paragraph */
.expertise-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 2rem;
}

/* Styling for the image container */
.expertise-image {
  flex: 1;
  max-width: 45%; /* Control the size of the image */
  padding: 1rem;
  text-align: center; /* Center the image in the container */
}

.expertise-image img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 10px; /* Rounded corners for the image */
  transition: transform 0.3s ease; /* Smooth zoom effect on hover */
}

.expertise-image img:hover {
  transform: scale(1.05); /* Slight zoom effect when hovering over the image */
}

/* Styling for the content section */
.expertise-content {
  flex: 1;
  padding: 1rem 2rem;
  color: #333;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
}

/* Styling for the paragraph text */
.expertise-paragraph {
  margin-bottom: 1.5rem;
  font-size: 1.125rem; /* Slightly larger font for readability */
  color: #555;
}

/* Highlighted section with a background and border */
.expertise-highlight {
  background-color: #fff;
  padding: 1.5rem;
  border-left: 5px solid #222;
  border-radius: 5px; /* Rounded corners for the highlight box */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Slight shadow for emphasis */
  font-size: 1.125rem;
  width: 100%;
}

/* Tablet Styles */
@media (max-width: 1024px) {
  .expertise-row {
    flex-direction: column;
    align-items: center;
  }

  .expertise-image,
  .expertise-content {
    max-width: 100%;
    padding: 1rem;
  }

  .expertise-paragraph,
  .expertise-highlight {
    font-size: 1rem;
  }
}

/* Mobile Styles */
@media (max-width: 600px) {
  .expertise-row {
    flex-direction: column;
    align-items: center;
  }

  .expertise-image,
  .expertise-content {
    max-width: 100%;
    padding: 0.5rem;
  }

  .expertise-paragraph,
  .expertise-highlight {
    font-size: 0.875rem;
    padding: 1rem;
  }

  .expertise-highlight {
    border-left: 3px solid #222;
  }
}

  

  

/*  */
/* Container for the expertise grid section */
.expertise-grid-container {
  display: flex;
  justify-content: center;
  padding: 2rem;
  background-color: #f4f4f4; /* Light grey background */
  border-radius: 10px; /* Rounded corners for the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

/* Grid layout for expertise cards */
.expertise-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)); /* Responsive grid */
  gap: 2rem;
  max-width: 1200px;
  width: 100%;
}

/* Each grid item (card) */
.expertise-grid-item {
  background-color: #fff; /* White background for each card */
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Light shadow for cards */
  overflow: hidden; /* Ensure content stays within the card */
  transition: transform 0.3s ease; /* Smooth scaling effect */
}

.expertise-grid-item:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Card content styling */
.expertise-card {
  padding: 1.5rem;
  text-align: center;
}

.expertise-image {
  width: 100%;
  height: 100%;
  border-radius: 5px; /* Rounded corners for the image */
  margin-bottom: 1rem;
  max-width: 90%;
}

.expertise-title-img {
  font-family: 'Playfair Display', serif;
  font-size: 1.5rem;
  color: #222;
  margin-bottom: 1rem;
  position: relative;
}

.expertise-title-img::before {
  content: '';
  position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  height: 3px;
  width: 50px;
  background-color: #111;
}

.expertise-description {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 0;
  font-family: 'Arial', sans-serif;
}

/* Tablet and mobile responsiveness */
@media (max-width: 768px) {
  .expertise-card {
    padding: 1rem;
  }

  .expertise-title-img {
    font-size: 1.25rem;
  }

  .expertise-description {
    font-size: 0.875rem;
  }
}

@media (max-width: 480px) {
  .expertise-title-img {
    font-size: 1.1rem;
  }

  .expertise-description {
    font-size: 0.75rem;
  }
}
  



  /*  */

  /* Container for the entire section */
/* Base Styles */
/* Main container for the philosophy section */
.philosophy-section {
  position: relative;
  padding: 4rem 2rem;
  background: url('https://patelinfra.com/images/homepage/section_bg.jpg') center/cover no-repeat;
  color: #fff;
  text-align: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Gradient overlay */
.gradient-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.3));
  z-index: 1;
}

/* Title styling */
.philosophy-title {
  position: relative;
  z-index: 2;
  font-family: 'Playfair Display', serif;
  margin-bottom: 3rem;
}

.philosophy-title h2 {
  font-size: 3rem;
  margin: 0;
  letter-spacing: 2px;
}

.philosophy-title h2:first-child {
  color: #f8b400; /* Highlight color for "OUR" */
}

.philosophy-title h2:last-child {
  color: #fff; /* White color for "PHILOSOPHY" */
}

/* Content list styling */
.philosophy-content {
  position: relative;
  z-index: 2;
}

.philosophy-list {
  list-style: none;
  padding: 0;
  margin: 0 auto;
  max-width: 800px;
  text-align: left;
}

.philosophy-list li {
  font-size: 1.25rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 2.5rem;
}

.philosophy-list li .arrow {
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.5rem;
  color: #f8b400; /* Arrow color */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .philosophy-title h2 {
    font-size: 2.5rem;
  }

  .philosophy-list li {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .philosophy-title h2 {
    font-size: 2rem;
  }

  .philosophy-list li {
    font-size: 1rem;
  }
}



  /*  */
/* Main container for the vision & mission and values sections */
.section-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3rem 2rem;
  background: linear-gradient(135deg, rgba(210, 208, 208, 0.7), rgba(172, 172, 172, 0.5));
  /* color: #fff; */
  border-radius: 10px;
  margin-bottom: 2rem;
  margin-top: 2rem;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Flex container for image and content */
.section-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
  width: 100%;
  z-index: 2;
}

.section-content.reverse {
  flex-direction: row-reverse;
}

/* Image styling */
.section-image img {
  max-width: 400px;
  height: auto;
  border-radius: 10px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); */
}

/* Content styling */
.section-text {
  flex: 1;
}

.section-text h2 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #f8b400;
  font-family: 'Playfair Display', serif;
}

.section-text ul {
  list-style: none;
  padding: 0;
}

.section-text li {
  font-size: 1.25rem;
  line-height: 1.8;
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 2rem;
}

.section-text li::before {
  content: '•';
  position: absolute;
  left: 0;
  top: 0;
  font-size: 1.5rem;
  color: #f8b400;
}

/* Responsive adjustments */
/* Responsive adjustments */
@media (max-width: 768px) {
  .section-container {
    flex-direction: column;
    padding: 2rem 1rem;
  }

  .section-content {
    flex-direction: column;
  }

  .section-image {
    display: none; /* Hide the image on mobile devices */
  }

  .section-text h2 {
    font-size: 2rem;
  }

  .section-text li {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .section-text h2 {
    font-size: 1.75rem;
  }

  .section-text li {
    font-size: 1rem;
  }
}




  
  /*  */
/* Container for Working Department section */
.working-department-container {
  margin: 20px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.working-department-container .title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  font-weight: bold;
}

.client-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 20px;
}

.client-item {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.client-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.client-link {
  text-decoration: none;
  font-size: 18px;
  color: #007bff;
  text-align: center;
  display: block;
}

.client-link:hover {
  color: #0056b3;
}

@media (max-width: 768px) {
  .client-item {
    flex: 1 1 45%;
  }
}

@media (max-width: 480px) {
  .client-item {
    flex: 1 1 100%;
  }

  .working-department-container .title {
    font-size: 20px;
  }

  .client-link {
    font-size: 16px;
  }
}

