/* Main container styling */
.clients-container {
  text-align: center;
  padding: 2rem;
  background: #f8f9fa; /* Light background color */
}

.clients-container h1 {
  font-size: 2.5rem;
  margin-bottom: 1.5rem;
  color: #333;
  font-family: 'Playfair Display', serif;
}

/* Styling for the logo container */
.clients-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem; /* Space between logos */
  padding: 1rem;
}

/* Individual client logo styling */
.client-logo {
  flex: 1 1 150px; /* Adjust as necessary */
  max-width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

.client-logo:hover {
  transform: scale(1.1); /* Slightly enlarges the logo on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); /* Deeper shadow on hover */
}

.client-logo img {
  max-width: 100%;
  height: auto;
  object-fit: contain;
  transition: opacity 0.3s ease; /* Smooth transition for opacity effect */
}

.client-logo:hover img {
  opacity: 0.9; /* Slightly fades the image on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .client-logo {
    flex: 1 1 120px;
    max-width: 120px;
  }
}

@media (max-width: 480px) {
  .client-logo {
    flex: 1 1 100px;
    max-width: 100px;
  }
}
